<template>
  <div class="home" id="home-pdf-container">
    <card type="primary" v-bind:title="this.getInquiryTitle()">
      <button
        class="btn btn-secondary btn-sm float-right"
        id="topClearSelectionsBtn"
        @click="startNewInquiry"
        style="margin-top: -5px;"
      >
        <i class="fa fa-times"></i>&nbsp; Clear Selections
      </button>
      <h5>Required Data</h5>

      <inquiry-single-details-pane
        :partners-list="partnersList"
        v-if="usingSingleDetailsPane()"
        :class-type-has-error="classTypeHasError"
        :class-type-error="classTypeError"
        :policy-class-has-error="policyClassHasError"
        :policy-class-error="policyClassError"
        :countries-has-error="countriesHasError"
        :countries-error="countriesError"
      />
      <inquiry-details-pane
        v-if="!usingSingleDetailsPane()"
        :producing-office-has-error="producingOfficeHasError"
        :producing-office-error="producingOfficeError"
        :class-type-has-error="classTypeHasError"
        :class-type-error="classTypeError"
        :policy-class-has-error="policyClassHasError"
        :policy-class-error="policyClassError"
        :countries-has-error="countriesHasError"
        :countries-error="countriesError"
      />

      <div class="row">
        <div class="col">
          <div class="form-group">
            <small class="text-muted"><em>* Required data</em></small>
            <tooltip-button
              class="float-right"
              text="Check Capabilities"
              btn-class="btn-success"
              :disabled="checkCapabilitiesButtonDisabled"
              disabled-tooltip-text="This programme has already been submitted"
              disabled-tooltip-level="success"
              disabled-tooltip-position="left"
              @click="checkCapabilities"
            >
              <template v-slot:icon>
                <span :class="{ 'd-none': !$store.getters.isLoadingWikiData }">
                  <i class="fa fa-sync fa-spin"></i>
                </span>
                <span :class="{ 'd-none': $store.getters.isLoadingWikiData }">
                  <i class="fa fa-search"></i>
                </span>
              </template>
            </tooltip-button>
          </div>
        </div>
      </div>
    </card>
    <card type="primary">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <router-link role="tab" class="nav-link" to="/inquiry/countrySelect">
            Country Select
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            role="tab"
            class="nav-link"
            to="/inquiry/capabilities"
            :disabled="!capabilitiesTabActive"
            :event="capabilitiesTabActive ? 'click' : ''"
          >
            Capabilities
          </router-link>
        </li>
        <li class="nav-item" v-if="!inquiryOnlyMode()">
          <router-link
            role="tab"
            class="nav-link"
            to="/inquiry/programmeInstructions"
            :disabled="!$store.getters.programmeInstructionsActive"
            :event="$store.getters.programmeInstructionsActive ? 'click' : ''"
          >
            Programme Instructions
          </router-link>
        </li>
        <li
          class="nav-item"
          v-if="!inquiryOnlyMode() && usingCoverageElements()"
        >
          <router-link
            role="tab"
            class="nav-link"
            to="/inquiry/coverSelect"
            :disabled="!$store.getters.programmeInstructionsActive"
            :event="$store.getters.programmeInstructionsActive ? 'click' : ''"
          >
            Cover Select
          </router-link>
        </li>
        <li
          class="nav-item"
          v-if="!inquiryOnlyMode() && usingCoverageElements()"
        >
          <router-link
            role="tab"
            class="nav-link"
            to="/inquiry/coverReview"
            :disabled="!$store.getters.programmeInstructionsActive"
            :event="$store.getters.programmeInstructionsActive ? 'click' : ''"
          >
            Cover Review
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
              role="tab" class="nav-link" to="/inquiry/communication"
              :disabled="!$store.getters.programmeInstructionsActive"
              :event="$store.getters.programmeInstructionsActive ? 'click' : ''"
          >
            Communication
          </router-link>
        </li>
        <li
          class="nav-item"
          v-if="underwritingPortalMode() && usingAddFileToInquiry()"
        >
          <router-link
            role="tab"
            class="nav-link"
            :to="'/inquiry/addFiles/' + $store.getters.programmeStateId"
            :disabled="!$store.getters.programmeInstructionsActive"
            :event="$store.getters.programmeInstructionsActive ? 'click' : ''"
          >
            Add Files
          </router-link>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="countrySelect"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <router-view
            :emailAddress="emailAddress"
            :clientName="clientName"
            :clientId="clientId"
            :inceptionDate="inceptionDate"
            :capabilitiesActive="capabilitiesTabActive"
            :checkCapabilitiesButtonDisabled="checkCapabilitiesButtonDisabled"
            @check-capabilities="checkCapabilities"
            @change-input="changeInput"
            @review-local-instructions="reviewLocalInstructions"
          >
          </router-view>
        </div>
      </div>
    </card>
  </div>
</template>

<style>
.nav-item .nav-link {
  cursor: pointer;
}
.nav-item .nav-link[disabled] {
  cursor: default;
  color: #666;
}
.nav-tabs .nav-link.router-link-active,
.nav-tabs .nav-link.router-link-active:focus {
  border-color: #dee2e6 #dee2e6 #fff;
  color: #333;
}
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

tr.commonRow td {
  background-color: #f1f1f1;
}

tr.commonSeparator {
  height: 20px;
}
tr.commonSeparator td {
  border-top: 1px solid #dee2e6;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Questionable, but Vue-Select doesn't have options for displaying validation state */
div.is-invalid .vs__dropdown-toggle,
div.countries-invalid {
  border-color: red;
}
</style>

<script>
import CurrencyFormattedInput from "../components/CurrencyFormattedInput";
import Card from "../components/Cards/Card";
import FeaturesMixin from "@/mixins/features.mixin";
import TooltipButton from "../components/TooltipButton";
import axios from "axios";
import * as Sentry from "@sentry/vue";
import InquiryDetailsPane from "../components/InquiryDetailsPane";
import InquirySingleDetailsPane from "../components/InquirySingleDetailsPane";

export default {
  name: "Inquiries",
  components: {
    InquiryDetailsPane,
    InquirySingleDetailsPane,
    CurrencyFormattedInput,
    Card,
    TooltipButton
  },
  mixins: [FeaturesMixin],
  computed: {
    countryListString() {
      return this.$store.getters.getCountryList;
    },
    requiredFieldsPopulated() {
      return (
        this.$store.getters.getSelectedCountries.length > 0 &&
        this.$store.getters.classType !== null &&
        this.$store.getters.policyClass !== null
      );
    },
    capabilitiesTabActive() {
      return (
        !this.fieldsChangedWithoutReload &&
        this.$store.getters.localPolicies.length !== 0
      );
    },
    checkCapabilitiesButtonDisabled() {
      return this.$store.getters.submitted;
    }
  },
  data: () => {
    return {
      emailAddress: "",
      clientName: "",
      clientId: "",
      inceptionDate: new Date(),
      appliedCoverDataToCountries: false,
      fieldsChangedWithoutReload: false,
      maxPolicyLimit: 10000000, // Ten million (USD)
      producingOfficeHasError: false,
      producingOfficeError: "",
      classTypeHasError: false,
      classTypeError: "",
      policyClassHasError: false,
      policyClassError: "",
      countriesHasError: false,
      countriesError: "",
      partnersList: []
    };
  },
  methods: {
    // TODO: Refactor me - This makes the input boxes feel really sluggish, as it's saving to the Vuex store with every character input
    sendInput(value, type) {
      const payload = {};
      payload[type] = value;
      this.$store.dispatch("setPolicyDetail", payload);
    },
    setProgrammeName(event) {
      this.$store.commit("setProgrammeName", {
        name: event.target.value
      });
    },
    async getPartnersList() {
      const url = process.env.VUE_APP_API_ENDPOINT + "/partner";

      try {
        let { data } = await axios.get(url, {
          withCredentials: true
        });
        this.partnersList = [
          ...new Set(
            data.map(function(value) {
              return value.partner_name;
            })
          )
        ];
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    },
    validateInputs() {
      let hasError = false;

      if (
        this.usingProducingOfficeDropdown() &&
        this.$store.getters.producing_office_id === null
      ) {
        hasError = true;
        this.producingOfficeHasError = true;
        this.producingOfficeError = "Producing Office is required";
      } else {
        this.producingOfficeHasError = false;
      }

      if (this.$store.getters.classType === null) {
        hasError = true;
        this.classTypeHasError = true;
        this.classTypeError = "Class Type is required";
      } else {
        this.classTypeHasError = false;
      }

      if (this.$store.getters.policyClass === null) {
        hasError = true;
        this.policyClassHasError = true;
        this.policyClassError = "Policy Class is required";
      } else {
        this.policyClassHasError = false;
      }

      if (this.$store.getters.getSelectedCountries.length === 0) {
        hasError = true;
        this.countriesHasError = true;
        this.countriesError = "At least one country is required";
      } else {
        this.countriesHasError = false;
      }

      return !hasError;
    },
    async checkCapabilities() {
      if (this.checkCapabilitiesButtonDisabled) {
        return;
      }

      if (!this.validateInputs()) {
        return;
      }

      this.fieldsChangedWithoutReload = false;
      await this.$store.dispatch("generateWikiCapabilitiesData");
      if (this.$store.getters.localPolicies.length > 0) {
        if (this.$route.name !== "Capabilities") {
          await this.$router.push({ name: "Capabilities" });
        }
        if (this.usingCoverageElements()) {
          await this.$store.dispatch("getCoverageTemplates");
        }
      } else {
        this.$toasted.info("No wiki data available for the selected countries");
      }
    },
    changeInput(data) {
      if (data.type === "emailAddress") {
        this.emailAddress = data.value;
      } else if (data.type === "clientName") {
        this.clientName = data.value;
      } else if (data.type === "clientId") {
        this.clientId = data.value;
      } else if (data.type === "country") {
        this.clearResultsGoToCountrySelect();
      } else {
        this.inceptionDate = data.value;
      }
    },
    reviewLocalInstructions() {
      // Set the individual country includes to match the overall in cover select
      // (only the first time for initial setup)
      if (!this.appliedCoverDataToCountries) {
        this.$store.dispatch("applyCoverDataToLocalPolicies");
        this.appliedCoverDataToCountries = true;
      }
      this.$router.push({ name: "Cover Review" });
    },
    startNewInquiry() {
      this.$store.dispatch("startNewInquiry");
      this.clearResultsGoToCountrySelect();
    },
    clearResultsGoToCountrySelect() {
      this.fieldsChangedWithoutReload = true;
      if (this.$router.history.current.name !== "Country Select") {
        this.$router.push({ name: "Country Select" });
        document
          .getElementById("topClearSelectionsBtn")
          .scrollIntoView({ behavior: "smooth" });
      }
    },
    policyClassSelected(val) {
      this.policyClassHasError = false;
      this.policyClassError = "";
      this.clearResultsGoToCountrySelect();
      this.$store.dispatch("changePolicyClass", { policyClass: val });
    }
  },
  mounted() {
    // TODO: Move this to the defaults service, as it should be fired after the PO-ID header is setup
    if (this.usingAMENAElements()) {
      this.getPartnersList();
    }
    this.startNewInquiry();
    // Enable the capabilities tab if we have already received the relevant data
    if (this.$store.getters.localPolicies.length !== 0) {
      this.fieldsChangedWithoutReload = false;
    } else {
      this.fieldsChangedWithoutReload = true;
    }
  },
  updated() {
    if (parseInt(this.$route.params.reset) === 1) {
      this.startNewInquiry();

      this.$route.params.reset = "0";
    }
  }
};
</script>
